import { useEffect, useState } from 'react';

export default function useIsPageScrolled() {
  const [isScrolled, setIsScrolled] = useState<boolean>(false);

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > (window.innerWidth < 576 ? 10 : 40) && !isScrolled) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    }

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return { isScrolled };
}
