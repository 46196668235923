import config from '../config';
import { useEventProvider } from './event-context';

export function usePageUrl() {
  const { getPageName } = useEventProvider();

  const loginPageUrl = (): string => {
    return config.auth.loginUrl + `?s=${getPageName()}`;
  };

  const registerPageUrl = (): string => {
    return config.websiteBaseUrl + `/register?s=${getPageName()}`;
  };

  return { loginPageUrl, registerPageUrl };
}
