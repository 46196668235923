import message from 'antd/lib/message';
import Image from '@/src/shared/components/Image';
import { MessageContent } from './MessageContent';
import { v4 as uuidv4 } from 'uuid';
import type { ReactNode } from 'react';

const defaults = {
  duration: 10,
};

message.config({
  top: 20,
  duration: defaults.duration,
});

interface Options {
  duration?: number;
  ctaProps?: {
    label: string;
    onClick: () => void;
  };
  hideDismiss?: boolean;
}

export const notifySuccess = (content: string, options?: Options) => {
  const notifKey = uuidv4();
  return message.success({
    key: notifKey,
    content: (
      <MessageContent
        notifKey={notifKey}
        ctaProps={options?.ctaProps}
        hideDismiss={options?.hideDismiss}
      >
        {content}
      </MessageContent>
    ),
    className: 'custom success',
    duration: options?.duration ?? defaults.duration,
    icon: (
      <Image
        src="/images/icons/toast/success.svg"
        alt=""
        width={38}
        height={38}
        className="anticon"
      />
    ),
  });
};

export const notifyInfo = (content: string, options?: Options) => {
  const notifKey = uuidv4();
  return message.info({
    key: notifKey,
    content: (
      <MessageContent
        notifKey={notifKey}
        ctaProps={options?.ctaProps}
        hideDismiss={options?.hideDismiss}
      >
        {content}
      </MessageContent>
    ),
    className: 'custom info',
    duration: options?.duration ?? defaults.duration,
    icon: (
      <Image
        src="/images/icons/toast/info.svg"
        alt=""
        width={38}
        height={38}
        className="anticon"
      />
    ),
  });
};

export const notifyError = (content: ReactNode, options?: Options) => {
  const notifKey = uuidv4();
  return message.error({
    key: notifKey,
    content: (
      <MessageContent
        notifKey={notifKey}
        ctaProps={options?.ctaProps}
        hideDismiss={options?.hideDismiss}
      >
        {content}
      </MessageContent>
    ),
    className: 'custom error',
    duration: options?.duration ?? defaults.duration,
    icon: (
      <Image
        src="/images/icons/toast/error.svg"
        alt=""
        width={38}
        height={38}
        className="anticon"
      />
    ),
  });
};

export const notifyWarning = (content: string) =>
  message.warning({ content, className: 'custom warning' });
