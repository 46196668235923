import React from 'react';
import Link from '@/src/shared/components/Link';
import Image from '@/src/shared/components/Image';
import styles from './HeaderActions.module.scss';

export function GoToDashboardButton() {
  return (
    <Link href="/dashboard">
      <a className={styles.dashboard}>
        <span>Go To</span> Dashboard
        <div className={styles.img}>
          <Image src="/images/icons/icons-s-arrow-right-blue.svg" alt="" fill />
        </div>
      </a>
    </Link>
  );
}
