import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from '../reducer';
import type {
  OpenConfirmDowngradeSubscriptionPopup,
  OpenResumeSubscriptionPopup,
  OpenVideoPopupV2,
  PopupManagerState,
} from './types';

export const popupManagerSelector = (state: RootState): PopupManagerState =>
  state.popupManager;

export const selectCurrentVideo = createSelector(
  popupManagerSelector,
  (state) => state.video ?? ({} as typeof state.video)
);

export const selectRegionName = createSelector(
  popupManagerSelector,
  (state) => state.regionName
);

export const selectPage = createSelector(
  popupManagerSelector,
  (state) => state.page
);

export const selectPageSection = createSelector(
  popupManagerSelector,
  (state) => state.pageSection
);

export const selectIsDefaultVideo = createSelector(
  popupManagerSelector,
  (state) => state.isDefaultVideo
);

export const selectShareConfirmPopupData = createSelector(
  popupManagerSelector,
  (state) => state.shareConfirm
);

export const selectDownloadPopupData = createSelector(
  popupManagerSelector,
  (state) => state.download
);

export const selectDownloadUserVideoPopupData = createSelector(
  popupManagerSelector,
  (state) => state.downloadUserVideo
);

export const selectFreePlanPopupData = createSelector(
  popupManagerSelector,
  (state) => state.freePlan
);

export const selectUpgradePopupData = createSelector(
  popupManagerSelector,
  (state) => state.upgrade
);

export const selectEditVideoBrandingData = createSelector(
  popupManagerSelector,
  (state) => state.editVideoBranding
);

export const selectSchedulePopupData = createSelector(
  popupManagerSelector,
  (state) => state.schedule
);

export const selectScheduleCalendarPopupData = createSelector(
  popupManagerSelector,
  (state) => state.scheduleCalendar
);

export const selectPopups = createSelector(
  popupManagerSelector,
  (state) => state.popups ?? []
);

export const selectPopupTitle = createSelector(
  popupManagerSelector,
  (state) => state.popupTitle
);

export const selectVideoURL = createSelector(
  popupManagerSelector,
  (state) => state.videoURL
);

export const selectCtaText = createSelector(
  popupManagerSelector,
  (state) => state.ctaText
);

export const selectSource = createSelector(
  popupManagerSelector,
  (state) => state.source
);

export const selectQuery = createSelector(
  popupManagerSelector,
  (state) => state.query
);

export const selectRedirectUrl = createSelector(
  popupManagerSelector,
  (state) => state.redirectUrl
);

export const selectCurrentPopup = createSelector(selectPopups, (popups) => {
  return popups.length > 0 ? popups[popups.length - 1] : null;
});

export const selectCurrentNews = createSelector(
  popupManagerSelector,
  (state) => state.news
);

export const selectCurrentTestimonial = createSelector(
  popupManagerSelector,
  (state) => state.testimonial
);

export const selectJourney = createSelector(
  popupManagerSelector,
  (state) => state.selectedJourney
);

export const selectLeadGenerationPopupData = createSelector(
  popupManagerSelector,
  (state) => state.leadGeneration
);

export const selectFollowUpMessagesPopupData = createSelector(
  popupManagerSelector,
  (state) => state.selectedLead
);

export const selectLandingSharedPopupData = createSelector(
  popupManagerSelector,
  (state) => state.landingShared
);

export const selectEditInfoData = createSelector(
  popupManagerSelector,
  (state) => state.editInfo
);

export const selectYoutubeVideoData = createSelector(
  popupManagerSelector,
  (state) => state.youtubeVideo
);

export const selectIframeData = createSelector(
  popupManagerSelector,
  (state) => state.iframePopup
);

export const selectAcceptFacebookTerms = createSelector(
  popupManagerSelector,
  (state) => state.acceptTOS
);

export const selectAddScheduledPostBrokerage = createSelector(
  popupManagerSelector,
  (state) => state.post
);

export const selectConnectAllSocialsPopupData = createSelector(
  popupManagerSelector,
  (state) => state.connectAllSocial
);

export const selectSendSetupEmailPopupData = createSelector(
  popupManagerSelector,
  (state) => state.setupEmail
);

export const selectSendSetupReminderEmailPopupData = createSelector(
  popupManagerSelector,
  (state) => state.setupReminder
);

export const selectBrokerageAgentImagePostPopupData = createSelector(
  popupManagerSelector,
  (state) => state.brokerageAgentImagePost
);

export const selectConfirmPopupData = createSelector(
  popupManagerSelector,
  (state) => state.details
);

export const selectSetLocationPopupData = createSelector(
  popupManagerSelector,
  (state) => state.locationData
);

export const selectEditCrmActionTemplatePopupData = createSelector(
  popupManagerSelector,
  (state) => state.editCrm
);

export const selectDemoPopupData = createSelector(
  popupManagerSelector,
  (state) => state.demo
);

export const selectCalendarSettingsData = createSelector(
  popupManagerSelector,
  (state) => state.calendarSettingsData
);

export const selectUpgradeSuccessPopupData = createSelector(
  popupManagerSelector,
  (state) => state.upgradeSuccessPopupData
);

export const selectManageUserGroupPopupData = createSelector(
  popupManagerSelector,
  (state) => state.manageUserGroupPopupData
);

export const selectHyperlocalVideoInfoData = createSelector(
  popupManagerSelector,
  (state) => state.hyperlocalVideoInfo
);

export const selectCompleteYourProfileData = createSelector(
  popupManagerSelector,
  (state) => state.completeYourProfilePopup
);

export const selectCalendarPreviewData = createSelector(
  popupManagerSelector,
  (state) => state.calendarPreview
);

export const selectShareListingData = createSelector(
  popupManagerSelector,
  (state) => state.shareListing
);

export const selectEditListingData = createSelector(
  popupManagerSelector,
  (state) => state.editListing
);

export const selectAddListingData = createSelector(
  popupManagerSelector,
  (state) => state.addListing
);

export const selectAddListingConfirmData = createSelector(
  popupManagerSelector,
  (state) => state.addListingConfirm
);

export const selectAddListingFromZillowProfileConfirmData = createSelector(
  popupManagerSelector,
  (state) => state.addListingConfirmFromZillowProfile
);

export const selectAfterPaymentData = createSelector(
  popupManagerSelector,
  (state) => state.afterPaymentPopup
);

export const selectSocialsSettingsData = createSelector(
  popupManagerSelector,
  (state) => state.socialsSettings
);

export const selectPhoneVerificationInfoData = createSelector(
  popupManagerSelector,
  (state) => state.info
);

export const selectUploadHeadshotPopupData = createSelector(
  popupManagerSelector,
  (state) => state.headshotPopup
);

export const selectImportNewsInfoData = createSelector(
  popupManagerSelector,
  (state) => state.importNews
);

export const selectVideoCreatorPopupData = createSelector(
  popupManagerSelector,
  (state) => state.videoCreatorPopup
);

export const selectVideRecorderPopupData = createSelector(
  popupManagerSelector,
  (state) => state.videoRecorderPopup
);

export const selectPremiumBrandingPopupData = createSelector(
  popupManagerSelector,
  (state) => state.premiumBrandingPopupData
);

export const selectIsVideoCreatorPopupOpen = createSelector(
  selectPopups,
  (popups) => popups.includes('video-creator-popup')
);

export const selectCouponPopupData = createSelector(
  popupManagerSelector,
  (state) => state.couponInfo
);

export const selectVideoV2PopupData = createSelector(
  popupManagerSelector,
  (state) => state.video as OpenVideoPopupV2['payload']['video']
);

export const selectResumeSubscriptionPopup = createSelector(
  popupManagerSelector,
  (state) =>
    state.resume_date as OpenResumeSubscriptionPopup['payload']['resume_date']
);

export const selectConfirmDowngradeSubscriptionPopup = createSelector(
  popupManagerSelector,
  (state) =>
    state.downgradeData as OpenConfirmDowngradeSubscriptionPopup['payload']['downgradeData']
);
