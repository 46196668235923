import { clsx } from 'clsx';
import dynamic from 'next/dynamic';
import React, { ReactNode, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUserInfo } from '@/redux/user/selectors';
import { useIsMobile } from '@/src/shared/hooks/utils/useIsMobile';
import RoomvuLogo from './RoomvuLogo';
import { HeaderActions } from './HeaderActions';
import { HeaderProvider } from './HeaderProvider';
import { Header } from './Header';
import styles from './index.module.scss';

const BreakingNews = dynamic(() => import('./BreakingNews'));
const MobileMenuButton = dynamic(() => import('./MobileMenuButton'));
const Navigation = dynamic(() => import('./Navigation'));

type Props = {
  showBreakingNews?: boolean;
  bgColor?: string;
  noMenu?: boolean;
  showBookingButton?: boolean;
  showSendFreeCopyButton?: boolean;
  onClickSendFreeCopyButton?: () => void;
  onBookingButtonClick?: () => void;
  doNotShowGoToDashboardButton?: boolean;
  classes?: { root?: string; rightContentClasses?: string };
  menuClasses?: { menuItem?: string };
  whiteLogo?: boolean;
  rightContentBothHeaderBtn?: boolean;
  rightContent?: any;
  doNotShowGetStartFree?: boolean;
  bookingButtonLabel?: ReactNode;
  onRequestDemoButtonClick?: () => void;
  onGetStartedButtonClick?: () => void;
  onLoginClick?: () => void;
};

export default function HomeHeader({
  showBreakingNews,
  doNotShowGoToDashboardButton,
  showBookingButton,
  showSendFreeCopyButton,
  onClickSendFreeCopyButton,
  noMenu,
  bgColor,
  classes,
  onBookingButtonClick,
  menuClasses,
  whiteLogo,
  rightContent,
  rightContentBothHeaderBtn = false,
  doNotShowGetStartFree = false,
  bookingButtonLabel,
  onRequestDemoButtonClick,
  onGetStartedButtonClick,
  onLoginClick,
}: Props) {
  const user = useSelector(selectUserInfo);
  const [showMenu, setShowMenu] = useState(false);
  const isMobile = useIsMobile('(max-width:1024px)');

  const shouldDisplayMenuItems = () => {
    if (noMenu === true) return false;
    if (isMobile) {
      return showMenu;
    }
    return true;
  };

  return (
    <HeaderProvider>
      {showBreakingNews && <BreakingNews />}

      <Header
        className={classes?.root}
        bgColor={bgColor}
        showBreakingNews={showBreakingNews}
      >
        <nav className={styles.wrapper}>
          {!noMenu && isMobile && (
            <MobileMenuButton onClick={() => setShowMenu(true)} />
          )}

          <RoomvuLogo color={whiteLogo ? 'white' : 'brand'} />

          {shouldDisplayMenuItems() && (
            <Navigation
              showMenu={showMenu}
              onClose={() => setShowMenu(false)}
              userExist={!!user}
              menuClasses={menuClasses}
            />
          )}
          {rightContent ? (
            <div
              className={clsx(
                styles.rightContent,
                classes?.rightContentClasses
              )}
            >
              {rightContentBothHeaderBtn && (
                <div className={styles.rightContentBothHeaderBtn}>
                  <HeaderActions
                    noMenu={noMenu}
                    showBookingButton={showBookingButton}
                    onBookingButtonClick={onBookingButtonClick}
                    doNotShowGoToDashboardButton={doNotShowGoToDashboardButton}
                    showSendFreeCopyButton={showSendFreeCopyButton}
                    onClickSendFreeCopyButton={onClickSendFreeCopyButton}
                    doNotShowGetStartFree
                    bookingButtonLabel={bookingButtonLabel}
                    onRequestDemoButtonClick={onRequestDemoButtonClick}
                    onGetStartedButtonClick={onGetStartedButtonClick}
                    onLoginClick={onLoginClick}
                  />
                </div>
              )}
              {rightContent}
            </div>
          ) : (
            <HeaderActions
              noMenu={noMenu}
              showBookingButton={showBookingButton}
              onBookingButtonClick={onBookingButtonClick}
              doNotShowGoToDashboardButton={doNotShowGoToDashboardButton}
              showSendFreeCopyButton={showSendFreeCopyButton}
              onClickSendFreeCopyButton={onClickSendFreeCopyButton}
              doNotShowGetStartFree={doNotShowGetStartFree}
              bookingButtonLabel={bookingButtonLabel}
              onRequestDemoButtonClick={onRequestDemoButtonClick}
              onGetStartedButtonClick={onGetStartedButtonClick}
              onLoginClick={onLoginClick}
            />
          )}
        </nav>
      </Header>
    </HeaderProvider>
  );
}
