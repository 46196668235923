import { IVideo } from '@/types/video';
import config from '@/config';
import { IUser } from '@/types/user';

const { websiteBaseUrl } = config;

export const getUpgradePopupUrl = (plan, source, mode = '', proOffer = false) =>
  `${websiteBaseUrl}/dashboard/popups?popup=payment&plan=${plan}&ref=video${
    source !== '' ? `&source=${source}` : ''
  }${mode !== '' ? `&mode=${mode}` : ''}${proOffer ? `&offer=1` : ''}
  `;

export const openUpgradePopupPage = (plan, source = '', mode = '') =>
  window.location.replace(getUpgradePopupUrl(plan, source, mode));

export const getSearchUrl = (q: string) =>
  `${websiteBaseUrl}/dashboard/search?q=${q}`;

export const setStaticUrl = (url: string) => {
  if (url.indexOf('http') === 0) {
    return url;
  }

  return `https://static.roomvu.com/${url}`;
};

export const getSmartAdsBaseUrl = (trc_src) => {
  const base =
    process.env.NEXT_PUBLIC_PREV_DASHBOARD_URL || 'https://roomvu.com';

  let url = `${base}/dashboard/smart-ads?trc_src=` + trc_src;

  return url;
};

export const getSmartAdUrl = (
  goal: string,
  campaign: string,
  style: string,
  trc_src: string
) => {
  const base = getSmartAdsBaseUrl(trc_src);
  return `${base}&creative=${goal}-${campaign}-${style}`;
};

export const removeTokenFromUrl = (router) => {
  const search = window.location.search;
  const queryParams = new URLSearchParams(search);
  if (queryParams.has('token')) {
    queryParams.delete('token');
    router.push(
      { pathname: router.pathname, query: queryParams.toString() },
      undefined,
      { shallow: true }
    );
  }
};

/**
 * Free users can use embed or copy link feature for some videos
 * other videos fully denied
 * these are the list of fully denied videos
 * @param video
 * @returns
 */
export const isVideoFullDeniedForFreeUser = (video: IVideo) => {
  return (
    ['submarket', 'citymarket', 'brokerage', 'neighborhood'].indexOf(
      video.type
    ) > -1
  );
};

export function toIso8601DurationTimeFormat(seconds) {
  const hours = Math.floor(seconds / 3600);
  seconds = seconds % 3600;

  const minutes = Math.floor(seconds / 60);
  seconds = seconds % 60;

  if (!hours) return `PT${minutes}M${seconds}S`;

  return `PT${hours}H${minutes}M${seconds}S`;
}

export function updateQueryStringParameter(uri, key, value) {
  var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
  var separator = uri.indexOf('?') !== -1 ? '&' : '?';
  if (uri.match(re)) {
    return uri.replace(re, '$1' + key + '=' + value + '$2');
  } else {
    return uri + separator + key + '=' + value;
  }
}

export const minPrivilegeForVideo = (
  action: 'share' | 'download',
  userSubscription: IUser['subscription'],
  video: IVideo
) => {
  const min =
    action === 'share'
      ? video?.minimum_access_share
      : video?.minimum_access_download;
  if (
    userSubscription === 'none' &&
    ['basic', 'premium', 'platinum'].includes(min)
  )
    return min;
  if (userSubscription === 'basic' && ['premium', 'platinum'].includes(min))
    return min;
  if (userSubscription === 'premium' && ['platinum'].includes(min)) return min;
  return null;
};

/**
 * Show or hide Drift widget
 * @param show
 */
export const toggleDrift = (show: boolean) => {
  if (typeof window !== 'undefined' && window?.drift) {
    const driftElement = document.getElementById('drift-frame-controller');
    if (!show) {
      window.drift.hide();
      if (driftElement) driftElement.style.visibility = 'hidden';
    } else {
      window.drift.show();
      if (driftElement) driftElement.style.visibility = 'visible';
    }
  }
};

export const averageTimeToPost = (platform: string): string => {
  switch (platform) {
    case 'facebook':
    case 'wordpress':
      return '1-2 hours';
    case 'instagram_business':
    case 'instagram':
      return '3-4 hours';
    default:
      return 'half an hour';
  }
};
