import React from 'react';
import styles from './HeaderActions.module.scss';
import { usePageUrl } from '@/helpers/use-page-url';
import { Button } from '../../Button';
import Link from 'next/link';
import { useIsMobile } from '@/src/shared/hooks/utils/useIsMobile';

interface Props {
  onClick?: () => void;
}

export default function LoginButton({ onClick }: Props) {
  const { loginPageUrl } = usePageUrl();
  const isMobile = useIsMobile('(max-width: 768px)');

  return (
    <>
      {isMobile ? (
        <Link
          href={loginPageUrl()}
          rel="nofollow"
          onClick={() => {
            if (onClick) onClick();
          }}
        >
          <Button color="link-gray" size="lg" className={styles.loginButton}>
            Log in
          </Button>
        </Link>
      ) : (
        <a
          className={styles.login}
          href={loginPageUrl()}
          rel="nofollow"
          onClick={() => {
            if (onClick) onClick();
          }}
        >
          Login
        </a>
      )}
    </>
  );
}
