import React from 'react';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';

export type LinkProps = NextLinkProps & {
  children?: React.ReactNode;
  className?: string;
};

export default function Link({
  prefetch = false,
  legacyBehavior = true,
  children,
  className,
  ...rest
}: LinkProps): React.ReactElement {
  return (
    <NextLink
      {...rest}
      prefetch={prefetch}
      legacyBehavior={legacyBehavior}
      className={className}
    >
      {children}
    </NextLink>
  );
}
