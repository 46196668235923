import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { selectUserInfo } from '@/redux/user/selectors';
import styles from './HeaderActions.module.scss';
import { ActionsButton } from './ActionsButton';
import { GoToDashboardButton } from './GoToDashboardButton';
import LoginButton from './LoginButton';
import RequestDemoButton from './RequestDemoButton';
import ButtonDivider from './ButtonDivider';
import { useIsMobile } from '@/src/shared/hooks/utils/useIsMobile';

type Props = {
  noMenu?: boolean;
  showBookingButton?: boolean;
  bookingButtonLabel?: ReactNode;
  showSendFreeCopyButton?: boolean;
  doNotShowGetStartFree?: boolean;
  onClickSendFreeCopyButton?: () => void;
  onRequestDemoButtonClick?: () => void;
  onBookingButtonClick?: () => void;
  doNotShowGoToDashboardButton?: boolean;
  onGetStartedButtonClick?: () => void;
  onLoginClick?: () => void;
};

export const HeaderActions = ({
  showBookingButton,
  onBookingButtonClick,
  doNotShowGoToDashboardButton,
  onClickSendFreeCopyButton,
  showSendFreeCopyButton,
  doNotShowGetStartFree,
  noMenu,
  bookingButtonLabel,
  onRequestDemoButtonClick,
  onGetStartedButtonClick,
  onLoginClick,
}: Props) => {
  const user = useSelector(selectUserInfo);
  const isMobile = useIsMobile('(max-width: 768px)');

  return (
    <div className={styles.headerUser}>
      {user == null || doNotShowGoToDashboardButton ? (
        <>
          {isMobile && !noMenu && <LoginButton />}
          {!isMobile && !noMenu && (
            <div className={styles.loginButtons}>
              <RequestDemoButton onClick={onRequestDemoButtonClick} />
              <ButtonDivider />
              <LoginButton onClick={onLoginClick} />
            </div>
          )}
          <ActionsButton
            showBookingButton={showBookingButton}
            onBookingButtonClick={onBookingButtonClick}
            showSendFreeCopyButton={showSendFreeCopyButton}
            onClickSendFreeCopyButton={onClickSendFreeCopyButton}
            doNotShowGetStartFree={doNotShowGetStartFree}
            bookingButtonLabel={bookingButtonLabel}
            onGetStartedButtonClick={onGetStartedButtonClick}
          />
        </>
      ) : (
        <GoToDashboardButton />
      )}
    </div>
  );
};
