import type { AxiosResponse } from 'axios';
import { callGetApi, callPostApi } from '@/src/core/utils/api';
import {
  clientEraseCookie,
  clientSetCookie,
  serverSetCookie,
} from '@/features/authentication/utils/cookies';
import type { IUser } from '@/types/user';
import { authApi, loginApi } from '@/features/authentication';
import {
  LOG_OUT,
  SET_AUTH_STATUS,
  SET_LOGIN_STATUS,
  SET_USER_INFO,
  SET_USER_STATUS,
  SET_USER_TOKEN,
} from './actionTypes';
import { selectUserToken } from './selectors';

export const sendToLogin = () => ({
  type: SET_USER_STATUS,
  payload: 'NOT_LOGGED_IN',
});

export const setUserStatus = (newStatus) => ({
  type: SET_USER_STATUS,
  payload: newStatus,
});

export const setLoginStatus = (newStatus, data = {}) => ({
  type: SET_LOGIN_STATUS,
  payload: {
    status: newStatus,
    data,
  },
});

export const setUserToken = (token) => ({
  type: SET_USER_TOKEN,
  payload: token,
});

export const logOut = () => {
  clientEraseCookie('token');
  return { type: LOG_OUT };
};

export const startLogin = (username, password) => async (dispatch) => {
  dispatch(setLoginStatus('WAITING'));

  try {
    const loginResult = await callPostApi(loginApi, {
      username,
      password,
    });

    if (loginResult.status !== 200) throw new Error(loginResult.statusText);

    const token = loginResult.data.data.token;
    clientSetCookie('token', encodeURIComponent(token), 30);
    dispatch(setUserToken(token));
  } catch (err) {
    dispatch(
      setLoginStatus('FAILED', {
        errorMessage: err.message,
      })
    );
  }
};

export const loginWithToken = (token: string) => async (dispatch) => {
  serverSetCookie('token', encodeURIComponent(token), 30);
  const [, userInfo] = await Promise.all([
    dispatch(setUserToken(token)),
    dispatch(updateUserInfo(token)),
  ]);
  return Promise.resolve<IUser>(userInfo);
};

export const setAuthStatus = (newStatus, data = {}) => ({
  type: SET_AUTH_STATUS,
  payload: {
    status: newStatus,
    data,
  },
});

export const setUserInfo = (userInfo) => ({
  type: SET_USER_INFO,
  payload: userInfo,
});

export const updateUserInfo =
  (token?: string, showLoading = true) =>
  async (dispatch, getState) => {
    if (showLoading) dispatch(setAuthStatus('WAITING'));
    try {
      if (!token) {
        token = selectUserToken(getState());
      }
      if (!token)
        throw new Error('user is not authenticated! token is not available');
      const response: AxiosResponse<{
        data: IUser;
      }> = await callGetApi(`${authApi}`, { headers: { token: token } });
      const userInfo = response.data.data;
      dispatch(setUserInfo(userInfo));
      return Promise.resolve<IUser>(userInfo);
    } catch (err) {
      dispatch(
        setAuthStatus('FAILED', {
          errorMessage: err.message,
        })
      );
    }
  };
