import React, { ReactNode } from 'react';
import dynamic from 'next/dynamic';

const SendCopyButton = dynamic(() => import('./SendCopyButton'));
const BookDemoButton = dynamic(() => import('./BookDemoButton'));
const GetStartedButton = dynamic(() => import('./GetStartedButton'));

type Props = {
  showBookingButton?: boolean;
  showSendFreeCopyButton?: boolean;
  doNotShowGetStartFree?: boolean;
  onClickSendFreeCopyButton?: () => void;
  onBookingButtonClick?: () => void;
  onGetStartedButtonClick?: () => void;
  bookingButtonLabel?: ReactNode;
};

export function ActionsButton({
  showBookingButton,
  showSendFreeCopyButton,
  onClickSendFreeCopyButton,
  onBookingButtonClick,
  doNotShowGetStartFree,
  bookingButtonLabel,
  onGetStartedButtonClick,
}: Props) {
  if (showBookingButton) {
    return (
      <BookDemoButton onClick={onBookingButtonClick}>
        {bookingButtonLabel}
      </BookDemoButton>
    );
  }
  if (showSendFreeCopyButton) {
    return <SendCopyButton onClick={onClickSendFreeCopyButton} />;
  }
  if (!doNotShowGetStartFree) {
    return <GetStartedButton onClick={onGetStartedButtonClick} />;
  }
  return null;
}
