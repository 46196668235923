import type { ReactNode } from 'react';
import { clsx } from 'clsx';
import useIsPageScrolled from '@/src/shared/hooks/utils/useIsPageScrolled';
import styles from './index.module.scss';
import { useHeader } from './HeaderProvider';

interface Props {
  children: ReactNode;
  className?: string;
  bgColor?: string;
  showBreakingNews?: boolean;
}

export function Header({
  children,
  className,
  showBreakingNews,
  bgColor,
}: Props) {
  const { isScrolled } = useIsPageScrolled();
  const { menuExpanded } = useHeader();

  return (
    <header
      className={clsx(
        styles.container,
        {
          [styles.whiten]: isScrolled || menuExpanded,
          [styles.hasBreakingNews]: showBreakingNews,
        },
        className
      )}
      style={
        isScrolled || menuExpanded ? undefined : { backgroundColor: bgColor }
      }
    >
      {children}
    </header>
  );
}
