import React from 'react';
import styles from './HeaderActions.module.scss';
import useBookDemo from '@/src/shared/hooks/useBookDemo';
import { Button } from '@/src/shared/components/Button';

interface Props {
  onClick?: () => void;
}

export default function RequestDemoButton({ onClick }: Props) {
  const { openBookDemoPopup } = useBookDemo();

  const handleOpenChange = () => {
    if (onClick) onClick();
    openBookDemoPopup('header-help-button');
  };
  return (
    <Button
      className={styles.login}
      color="link"
      onClick={() => handleOpenChange()}
    >
      Request a Demo
    </Button>
  );
}
