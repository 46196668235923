import { useEventProvider } from '@/helpers/event-context';
import { PageSections } from '@/types/pages';

export function useBookDemoEvent() {
  const { sendEvent } = useEventProvider();

  const sendBookDemoEvent = (pageSection: PageSections) => {
    sendEvent('book_demo_clicked', null, {
      page_section: pageSection,
    });
  };

  return { sendBookDemoEvent };
}
