// eslint-disable-next-line no-restricted-imports
import ImageBase, { ImageProps as ImagePropsBase } from 'next/image';
import { shimmer, toBase64 } from './shimmer';

export interface ImageProps extends ImagePropsBase {
  loadingType?: 'shimmer' | 'none';
}

export default function Image({
  loadingType = 'shimmer',
  placeholder,
  width,
  height,
  fill,
  ...rest
}: ImageProps) {
  const src = rest.src || undefined;

  return (
    <ImageBase
      {...rest}
      src={src}
      width={width}
      height={height}
      fill={fill}
      placeholder={
        loadingType === 'shimmer'
          ? `data:image/svg+xml;base64,${toBase64(shimmer(width, height))}`
          : undefined
      }
    />
  );
}
