import { clsx } from 'clsx';
import { ButtonProps } from './types';
import styles from './Button.module.scss';
import dynamic from 'next/dynamic';
import type { IconProps } from '@/src/shared/components/Icon';

const Icon = dynamic<IconProps>(() =>
  import('@/src/shared/components/Icon').then((res) => res.Icon)
);
const Loading = dynamic(() => import('./Loading'));

export function Button({
  children,
  className,
  color,
  size = 'md',
  variant,
  startIcon,
  endIcon,
  fullWidth,
  loading,
  type = 'button',
  classes,
  ...rest
}: ButtonProps) {
  const getIconSize = () => {
    if (variant === 'icon' && size === '2xl') return 24;
    return 20;
  };
  return (
    <button
      className={clsx(className, styles.button, {
        // Variant
        [styles.primary]: color === 'primary',
        [styles.error]: color === 'error',
        [styles.success]: color === 'success',
        [styles.secondary]: color === 'secondary',
        [styles.secondaryGray]: color === 'secondary-gray',
        [styles.destructiveSecondary]: color === 'destructive-secondary',
        [styles.secondaryListItemGray]: color === 'secondary-list-item-gray',
        [styles.secondaryError]: color === 'secondary-error',
        [styles.tertiary]: color === 'tertiary',
        [styles.tertiaryGray]: color === 'tertiary-gray',
        [styles.tertiaryError]: color === 'tertiary-error',
        [styles.link]: color === 'link',
        [styles.linkGray]: color === 'link-gray',
        [styles.linkError]: color === 'link-error',
        [styles.iconButton]: variant === 'icon',
        // Size
        [styles.xxl]: size === '2xl',
        [styles.xl]: size === 'xl',
        [styles.lg]: size === 'lg',
        [styles.md]: size === 'md',
        [styles.sm]: size === 'sm',
        [styles.fullWidth]: fullWidth,
      })}
      type={type}
      {...rest}
    >
      {!loading && startIcon && (
        <Icon
          className={classes?.startIcon}
          name={startIcon}
          fontSize={getIconSize()}
        />
      )}
      {loading && <Loading size={size} />}
      {children}
      {endIcon && <Icon name={endIcon} fontSize={getIconSize()} />}
    </button>
  );
}
