import React, { ReactNode } from 'react';
import styles from '@/src/shared/utils/message/MessageContent.module.scss';
import { clsx } from 'clsx';
import message from 'antd/lib/message';
import { Icon } from '../../components/Icon';

interface Props {
  notifKey: string | number;
  children: ReactNode;
  ctaProps?: {
    label: string;
    onClick: () => void;
  };
  hideDismiss?: boolean;
}

export function MessageContent({
  children,
  notifKey,
  ctaProps,
  hideDismiss,
}: Props) {
  const handleClose = () => {
    message.destroy(notifKey);
  };
  return (
    <div className={styles.content}>
      <span className={styles.text}>{children}</span>
      <div className={styles.actions}>
        {!hideDismiss && (
          <button
            className={clsx(styles.dismissButton, styles.action)}
            onClick={handleClose}
          >
            Dismiss
          </button>
        )}
        {ctaProps != null && (
          <button
            className={clsx(styles.ctaButton, styles.action)}
            onClick={() => {
              handleClose();
              ctaProps.onClick();
            }}
          >
            {ctaProps.label}
          </button>
        )}
      </div>
      <button
        className={clsx(styles.action, styles.closeButton)}
        onClick={handleClose}
      >
        <Icon name="x" fontSize="inherit" />
      </button>
    </div>
  );
}
