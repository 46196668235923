import React, { createContext, ReactNode, useState } from 'react';

type HeaderContextType = {
  menuExpanded: boolean;
  setMenuExpanded: React.Dispatch<React.SetStateAction<boolean>>;
};

const HeaderContext = createContext<HeaderContextType>(null);

interface Props {
  children: ReactNode;
}

export function HeaderProvider({ children }: Props) {
  const [menuExpanded, setMenuExpanded] = useState(false);

  return (
    <HeaderContext.Provider value={{ menuExpanded, setMenuExpanded }}>
      {children}
    </HeaderContext.Provider>
  );
}

export function useHeader() {
  const context = React.useContext(HeaderContext);
  if (context === undefined) {
    throw new Error('useHeader must be used within a HeaderContext');
  }
  return context;
}
