import { setCookie } from 'cookies-next';

export function parseCookies(cookies: string) {
  if (!cookies) return {};
  return cookies.split(';').reduce<any>((obj, cookieString) => {
    const splitCookie = cookieString
      .split('=')
      .map((cookiePart) => cookiePart.trim());
    try {
      obj[splitCookie[0]] = JSON.parse(splitCookie[1]);
    } catch (error) {
      obj[splitCookie[0]] = splitCookie[1];
    }
    return obj;
  }, {});
}

export function clientSetCookie(name, value, days) {
  var expires = '';
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
}

export function clientGetCookie(name) {
  var nameEQ = name + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function serverGetCookie(cookie, name) {
  var nameEQ = name + '=';
  var ca = cookie ? cookie.split(';') : '';
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function serverSetCookie(name: string, value: string, days?: number) {
  let expires: Date | null = null;
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = date;
  }
  setCookie(name, value, {
    expires,
  });
}

export function clientEraseCookie(name) {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
