import { openDemoPopup } from '@/redux/popup-manager/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsDiamondUser,
  selectIsPlatinumUser,
  selectIsPremiumUser,
  selectIsProUser,
} from '@/redux/user/selectors';
import { CalendlyLinks } from '@/src/shared/constants/calendly';
import { useBookDemoEvent } from '@/src/core/hooks/useBookDemoEvent';
import { PageSections } from '@/types/pages';

const useBookDemo = () => {
  const dispatch = useDispatch();
  const isProUser = useSelector(selectIsProUser);
  const isPremiumUser = useSelector(selectIsPremiumUser);
  const isPlatinumUser = useSelector(selectIsPlatinumUser);
  const isDiamondUser = useSelector(selectIsDiamondUser);
  const { sendBookDemoEvent } = useBookDemoEvent();

  const openPopup = ({
    link,
    description,
  }: {
    link: string;
    description?: string;
  }) => {
    dispatch(
      openDemoPopup({
        link,
        description,
      })
    );
  };

  const openSupportPlatinumDiamondDemoPopup = (description?: string) => {
    openPopup({
      link: CalendlyLinks.roomvuSupportPlatinumDiamond,
      description,
    });
  };

  const openFreeOnBoardingSessionPopup = (description?: string) => {
    openPopup({
      link: CalendlyLinks.freeOnBoardingSession,
      description,
    });
  };

  const openSupportProPremiumDemoPopup = (description?: string) => {
    openPopup({
      link: CalendlyLinks.roomvuCS15min,
      description,
    });
  };

  const openBookDemoPopup = (
    pageSection: PageSections,
    dontSendEvent?: boolean,
    description?: string
  ) => {
    if (!dontSendEvent) sendBookDemoEvent(pageSection);
    if (isProUser || isPremiumUser) {
      openSupportProPremiumDemoPopup(description);
      return;
    }
    if (isPlatinumUser || isDiamondUser) {
      openSupportPlatinumDiamondDemoPopup(description);
      return;
    }
    openFreeOnBoardingSessionPopup(description);
  };

  return {
    openBookDemoPopup,
    openSupportPlatinumDiamondDemoPopup,
    openSupportProPremiumDemoPopup,
    openFreeOnBoardingSessionPopup,
  };
};

export default useBookDemo;
