import Image from '@/src/shared/components/Image';
import styles from './RoomvuLogo.module.scss';
import { clsx } from 'clsx';
import blueLogo from '@/public/next-public/images/logo/logo.svg';
import whiteLogo from '@/public/next-public/images/logo/logo-white.svg';

type Props = {
  className?: string;
  color?: 'white' | 'brand';
  redirectToHomepageOnClick?: boolean;
};

export default function RoomvuLogo({
  color = 'brand',
  className,
  redirectToHomepageOnClick = true,
}: Props) {
  const logo = (
    <Image
      alt="Roomvu logo"
      src={color === 'white' ? whiteLogo : blueLogo}
      fill
    />
  );
  if (redirectToHomepageOnClick) {
    return (
      <a
        className={clsx(styles.roomvuLogo, styles.clickable, className)}
        href="https://www.roomvu.com"
      >
        {logo}
      </a>
    );
  }
  return <span className={clsx(styles.roomvuLogo, className)}>{logo}</span>;
}
